import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import ParksView from "../views/ParksView.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "home",
        component: ParksView,
    },
    {
        path: "/park/:parkId?",
        name: "park",
        component: () => import("../views/ParkDetailView.vue"),
    },
    {
        path: "/login",
        name: "login",
        component: () => import("../components/AuthComponent.vue"),
    },
    {
        path: "/logout",
        name: "logout",
        component: () => import("../components/AuthComponent.vue"),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
